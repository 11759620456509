<template>
    <div class="supplier-selector" :data-supplier-id="supplier ? supplier.id : null">

        <label for="supplier"><strong>Supplier (Optional)</strong></label>
        <div class="d-flex justify-content-center align-items-center">
            <div class="form-group flex-grow-1 mr-2">
<!--                <input id="supplier" type="text" class="form-control" placeholder="Type to search supplier">-->
                <v-select label="name"
                          placeholder="Start typing to search for suppliers..."
                          inputId="supplier"
                          :options="suppliers"
                          @search="searchSuppliers"
                          @input="selectSupplier">
                    <template slot="option" slot-scope="supplier">
                        <strong>{{ supplier.name }}</strong>
                        <span class="text-muted" v-if="supplier.code.length">#{{ supplier.code }}</span>
                    </template>
                    <template slot="selected-option" slot-scope="supplier">
                        <div class="selected d-center">
                            <strong>{{ supplier.name }}</strong>
                            <span class="text-muted" v-if="supplier.code.length">#{{ supplier.code }}</span>
                        </div>
                    </template>
                </v-select>
            </div>
            <!--div class="browse form-group flex-grow-0">
                <button class="btn btn-dark">Browse</button>
            </div-->
        </div>

        <div class="supplier" v-if="supplier">
            <div class="border-left pl-3">
                <div class="supplisupplierer-name h3 mb-0">{{ supplier.name }}</div>
                <div v-if="supplier.code.length" class="supplier-code small font-weight-bold mb-1">#{{ supplier.code }}</div>
                <div class="contact-details">
                    <div v-if="supplier.contact_name.length" class="supplier-name font-weight-bold">{{ supplier.contact_name }}</div>
                    <div v-if="supplier.contact_phone.length" class="supplier-phone">{{ supplier.contact_phone }}</div>
                    <div v-if="supplier.contact_email.length" class="supplier-email">{{ supplier.contact_email }}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        name: "supplierSelector",

        data: () => {
            return {
                suppliers: [],
                supplier: null,
                axiosSource: null,
            }
        },

        methods: {


            selectSupplier(supplier){
                this.supplier = supplier;
            },

            searchSuppliers(search, loading){

                if(this.axiosSource){
                    this.axiosSource.cancel();
                }

                // Get new Token.
                this.axiosSource = axios.CancelToken.source();

                loading(true);

                axios.get('/api/supplier', {
                    params: { q: search },
                    cancelToken: this.axiosSource.token,

                }).then((res) => {
                    this.suppliers = res.data;

                }).catch((error) => {
                    if(axios.isCancel(error)) return;
                    // Handle Other Exceptions

                }).then(() => { // Always Executed
                    loading(false);
                    this.cancelAjax = null;
                });
            },
        },
    }
</script>

<style scoped>

</style>
