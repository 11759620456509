$(document).ready(function(){

    const $form = $('#form-purchase');
    if( !$form.length ) return;

    $form.submit((e) => { e.preventDefault(); });

    const $input_search = $('#item-search');
    const $items_list_wrap = $('#purchase-items');
    const $btn_create_purchase = $('.create-purchase');

    function template( item ){

        const $html = $('' +
            '<tr>' +
            '    <td><strong class="item-name"></strong>' +
            '        <div class="small item-description"></div>' +
            '    </td>' +
            '    <td>' +
            '        <div class="input-group">' +
            '            <input class="form-control item-price" type="number" step="0.01" min="0" placeholder="Price" value="">' +
            '            <div class="small input-group-append">' +
            '                <div class="input-group-text">/<span class="unit-singular"></span></div>' +
            '            </div>' +
            '        </div>' +
            '    </td>' +
            '    <td>' +
            '        <div class="input-group">' +
            '            <input class="form-control item-quantity" type="number" step="0.01" min="0" placeholder="Quantity" value="">' +
            '            <div class="small input-group-append">' +
            '                <div class="input-group-text unit-plural"></div>' +
            '            </div>' +
            '        </div>' +
            '    </td>' +
            '    <td class="text-right item-total"></td>' +
            '    <td class="text-center"><a class="text-danger item-delete" href="#"><i class="fas fa-times-circle fa-2x"></i></a></td>' +
            '</tr>');

        console.log( item );

        let categories = [];
        for(const i in item.categories){
            if( !item.categories.hasOwnProperty( i ) ) continue;
            categories.push( item.categories[i].name );
        }

        $html.data('item', item);
        $html.find('.item-id').val(item.id);
        $html.find('.item-name').html(item.name + ' (' + categories.join(', ') + ')');
        $html.find('.item-description').html(item.description);
        $html.find('.unit-singular').html(item.unit.name);
        $html.find('.unit-plural').html(item.unit.name_plural);

        return $html;

    }


    $items_list_wrap.on('click', '.item-delete', function (e) {
        e.preventDefault();
        $(this).parents('tr').remove();
    });


    $input_search

        .autoComplete({

            autoSelect: true,
            minLength: 1,

            resolverSettings: {
                url: $input_search.data('api-url'),
            },

            formatResult: (item) => {

                let categories = [];
                for(const i in item.categories){
                    if( !item.categories.hasOwnProperty( i ) ) continue;
                    categories.push( item.categories[i].name );
                }

                return {
                    id: item.id,
                    text: item.name + ' (' + categories.join(', ') + ')',
                }
            },

        })

        .on('autocomplete.select', (evt, item) => {
            let found = false;
            const $rows = $items_list_wrap.children();

            if( $rows.length === 0 ){
                $items_list_wrap.append( template( item ) );
            } else{
                $rows.each((i, e) => {

                    const $row = $(e);
                    console.log( $row.data('item') );
                    if( $row.data('item').id === item.id ){
                        found = true;
                    }

                    if( $rows.length - 1 === i && !found ){
                        $items_list_wrap.append( template( item ) );
                    }

                });
            }

            $input_search.val('');
        })

        .on('change', () => { $input_search.val('') });



    $form.on('change', '.item-price,.item-quantity', function(){
        const $row = $(this).parents('tr');
        let price = parseFloat( $row.find('.item-price').val() );
        if( isNaN( price ) ) price = 0;

        let quantity = parseFloat( $row.find('.item-quantity').val() );
        if( isNaN( quantity ) ) quantity = 0;

        $row.find('.item-total').html( (price * quantity).format() );

        let total_price = 0;
        const $rows = $items_list_wrap.children();
        $rows.each((i, e) => {
            const $row = $(e);
            let price = parseFloat( $row.find('.item-price').val() );
            if( isNaN( price ) ) price = 0;
            let quantity = parseFloat( $row.find('.item-quantity').val() );
            if( isNaN( quantity ) ) quantity = 0;

            total_price += price * quantity;

            if(i === $rows.length - 1){
                $form.find('.grand-total').html( total_price );
            }
        });

    });


    $btn_create_purchase.click(function(e){
        e.preventDefault();

        const items = [];

        const $rows = $items_list_wrap.find('tr');
        if( $rows.length === 0 ) return;

        $form.find('input,textarea,select,button').prop_disable();
        $form.find('.error-line').remove();
        $form.find('.is-invalid').removeClass('is-invalid');

        $rows.each((i, e) => {
            const $row = $(e);
            const item = $row.data('item');
            items.push({
                id: item.id,
                price: parseFloat( $row.find('.item-price').val() ),
                quantity: parseFloat( $row.find('.item-quantity').val() ),
            });

            // Last Loop ==================
            if( $rows.length - 1 === i ){

                const supplier_id = $('.supplier-selector').data('supplier-id');

                const data = {
                    date: $('#purchase-date').val(),
                    notes: $('#notes').val(),
                    items: items,
                    supplier_id: (typeof supplier_id != 'undefined' && supplier_id) ? supplier_id : null,
                };

                // console.log(data);
                // $form.find('input,textarea,select,button').prop_enable();
                // return;

                axios.post( $form.attr('action'), data )
                    .then( response => {
                        window.location = '/purchase/';
                    }, reason => {
                        // alert( reason.response.data.message );
                        $form.find('input,textarea,select,button').prop_enable();
                        const errors = flatObjectToHierarchical( reason.response.data.errors );
                        console.log( errors );

                        if( typeof errors.items !== 'undefined' ){
                            for( const index in errors.items ){
                                if( !errors.items.hasOwnProperty( index ) ) continue;

                                const $row = $rows.eq(index);
                                for( const field in errors.items[index] ){
                                    if( !errors.items[index].hasOwnProperty( field ) ) continue;

                                    const $field = $row.find('.item-' + field );
                                    const messages = errors.items[index][field];
                                    console.log( messages );
                                    if( messages.length === 0 ) continue;

                                    $field.addClass('is-invalid');
                                    $field.parents('td')
                                        .append(
                                            $('<div class="error-line my-2 text-danger font-weight-bold"></div>')
                                                .append(messages[0])
                                        );
                                }
                            }
                        }

                    })
            }

        });


    });

});
