$(document).ready(function () {

    const $form_pos = $('#form-pos');
    if( !$form_pos.length ) return;


    const $input_search = $('#search-keywords');
    const $input_category = $('#search-category');
    const $category_items_wrap = $('#category-items');
    const $category_items = $('#category-items .category-item');
    const $items_list = $('#items-list');

    $category_items_wrap.on('mousewheel', (e) => {
        const pos = $category_items_wrap.scrollLeft();
        $category_items_wrap.scrollLeft(pos + e.originalEvent.deltaY);
    });

    $category_items.click(function(){
        let category = $(this).data('category');
        if(typeof category === 'undefined') category = '';
        $input_category
            .val(category)
            .change();
    });


    function search_item_template( item ) {
        const $html = $('' +
            '<div class="col-sm-4 col-md-3 mb-3">' +
            '   <a href="#" class="item shadow">' +
            '       <img src="" alt="">' +
            '       <span class="detail">' +
            '           <span class="name"></span>' +
            '           <span class="price">₹ <span class="value"></span> / <span class="unit"></span><span class="stock"></span></span>' +
            '       </span>' +
            '   </a>' +
            '</div>');

        let image = item.image;
        if( !image ) image = '/images/Box-128.png';

        $html.find('.name').html( item.name );
        $html.find('.price .value').html( item.price );
        $html.find('.price .unit').html( item.unit.name );
        $html.find('a').data('item', item);
        $html.find('img').attr('src', image);

        if( item.has_stock ){
            $html.find('.stock').html( ' &mdash; ' + item.stock.current_stock )
        }

        return $html;

    }


    let request_source = null;
    function search_items(){

        if( request_source ){
            request_source.cancel('Operation canceled by the user.');
        }

        const params = {
            limit: 20,
            is_saleable: true,
            q: $input_search.val(),
            category: $input_category.val(),
            in_stock: true,
            is_active: true,
        };

        $category_items.filter('.active').removeClass('active');
        if(typeof params.category !== 'undefined' && params.category !== ''){
            $category_items.filter('[data-category="' + params.category +'"]').addClass('active');
        }else{
            $category_items.first().addClass('active');
        }

        let url = $form_pos.data('items-url') + '?' + $.urlParams(params);

        const CancelToken = axios.CancelToken;
        request_source = CancelToken.source();

        $items_list.empty();
        axios.get( url, { cancelToken: request_source.token })
            .then((response) => {
                const items = response.data;
                for( const i in items ){
                    if( !items.hasOwnProperty(i) ) continue;
                    $items_list.append( search_item_template( items[i] ) );
                }
            })
            .catch((reason) => {
                // console.log( reason );
            });

        // const $html = item_template(  );
    }

    // Search once on load by default.
    search_items();

    let last_search_keywords = '';
    let search_timer = null;

    $input_category.on('change', search_items);
    $input_search.on('keyup', () => {

        if( search_timer ){
            clearTimeout( search_timer );
        }

        search_timer = setTimeout(() => {
            if( last_search_keywords !== $input_search.val() ){
                search_items();
            }
            last_search_keywords = $input_search.val();
        }, 300);

    });




    const $cart_item_list = $('#cart-item-list');

    $cart_item_list.on('click', '.item-remove', function(e){
        e.preventDefault();
        $(this).parents('tr').remove();
        setTimeout( calculate_price, 10 );
    });


    function cart_item_template( item ){

        const $html = $('' +
            '<tr>' +
            '   <td class="item-name"></td>' +
            '   <td><input type="number" class="form-control form-control-sm text-right item-price" min="0.01" step="0.01"></td>' +
            '   <td><input type="text" class="form-control form-control-sm text-right item-quantity" value="1" min="0" step="1" data-decimals="2"></td>' +
            '   <td class="text-right"><span class="item-unit"></span></td>' +
            '   <td class="text-right">₹ <span class="item-amount"></span></td>' +
            '   <td class="text-center item-remove">' +
            '       <a href="#" class="text-danger"><i class="fas fa-trash-alt"></i></a>' +
            '   </td>' +
            '</tr>');

        $html.data('item', item);
        $html.find('.item-name').html(item.name);
        $html.find('.item-price').val(item.price);
        $html.find('.item-quantity').val(1);
        $html.find('.item-unit').html(item.unit.name_plural);
        $html.find('.item-amount').html(item.price);

        setTimeout(() => {
            $html.find('.item-quantity').inputSpinner({
                buttonsWidth: 'auto'
            });
        }, 100);

        return $html;
    }

    // Send Items to Cart
    $items_list.on('click', '.item', function(e){
        e.preventDefault();
        const $item = $(this);
        const item = $item.data('item');
        const $cart_items = $cart_item_list.children();

        if( $cart_items.length === 0  ){
            $cart_item_list.append( cart_item_template( item ) );
            setTimeout( calculate_price, 10 );
        }

        let found = false;
        $cart_items.each(function(i, e){
            const $cart_item = $(e);
            if( $cart_item.data('item').id === item.id ){
                found = true;
            }

            // Last Loop -----------------
            if( $cart_items.length - 1 === i ){
                if( !found ){
                    $cart_item_list.append( cart_item_template( item ) );
                    setTimeout( calculate_price, 10 );
                }
            }

        });

        $input_search.focus();
    });


    const $cart_total = $('#cart-total');
    function calculate_price(){
        const $rows = $cart_item_list.children();
        let total_amount = 0;

        if( $rows.length === 0 ){
            $cart_total.html(total_amount);
            calculate_payment_return();
        }

        $rows.each((i, e) => {

            const $row = $(e);
            const price = $row.find('.item-price').val();
            const quantity = $row.find('.item-quantity').val();
            let amount = price * quantity;
            total_amount += amount;

            amount = parseInt( amount * 100 ) / 100;
            total_amount = parseInt( total_amount * 100 ) / 100;

            $row.find('.item-amount').html(amount);

            if( $rows.length - 1 === i ){
                $cart_total.html(total_amount);
                calculate_payment_return();
            }
        });
    }

    $cart_item_list.on('change', 'input', calculate_price);
    $cart_item_list.on('keyup', 'input', calculate_price);



    const $payment_received = $('#payment-received');
    const $payment_return = $('#payment-return');

    $payment_received.add($payment_return).focus(function(e){ $(this).select(); });

    $payment_received.change(calculate_payment_return);
    $payment_received.keyup(calculate_payment_return);

    function calculate_payment_return(){
        const cart_total = parseFloat( $cart_total.html() );
        const payment_received = $payment_received.val();
        const payment_return = ( payment_received - cart_total );
        $payment_return.val( payment_return );
    }



    // const $input_customer_number = $('#customer-number');
    // const $input_customer_name = $('#customer-name');
    //
    // $input_customer_number.change(function(){
    //
    //     const phone = $.trim( $input_customer_number.val() );
    //     if( phone.length === 0 ){
    //         // TODO: Remove the name field.
    //         return;
    //     }
    //
    //     let url = $input_customer_number.data('api-url');
    //     url += '?' + $.urlParams({ phone: phone });
    //
    //     axios.get( url )
    //
    //         .then((response) => {
    //             const customer = response.data.pop();
    //             $input_customer_name.val( customer.name );
    //         })
    //
    //         .catch((reason) => {
    //             // console.log( reason );
    //         });
    // });


    const $customer_selector = $('.customer-selector');

    $form_pos.submit(function(e){
        e.preventDefault();
        $form_pos.addClass('loading');
        $form_pos.find('[type="submit"]').prop('disabled', true);

        const $rows = $cart_item_list.find('tr');
        const items = {};

        if( $rows.length === 0 ){
            alert('There are no items in cart.');
        }

        $rows.each((i, e) => {
            const $row = $(e);
            const item = $row.data('item');

            items[ item.id ] = {
                price: $row.find('.item-price').val(),
                quantity: $row.find('.item-quantity').val(),
            };

            if( $rows.length - 1 === i ){

                const data = {
                    customer_name: $customer_selector.data('customer-name'),
                    customer_number: $customer_selector.data('customer-phone'),
                    date: $('#sale-date').val(),
                    items: items
                };


                axios.post( $form_pos.attr('action'), data )
                    
                    .then(response => {
                        $form_pos.removeClass('loading');
                        const sale = response.data;
                        window.open('/sale/' + sale.id + '/print');
                        window.location.reload();
                    }, reason => {
                        alert( reason.response.data.message );
                        $form_pos.removeClass('loading');
                    });
            }

        });

    });

});
