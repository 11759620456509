/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


require('./bootstrap');


// Utilities ================
require('./utils/confirm');
require('./utils/dotted-to-obj');
require('./utils/number-format');
require('./utils/conditional-show-hide');
require('./utils/action-delete');
require('./utils/custom-action');
require('./utils/prop-enable-disable');
require('./utils/ucFirst');
require('./utils/urlParams');
require('./utils/openInNewWindow');
require('./utils/daterange');
require('./utils/fullscreen');


// Components ===============
require('./components/chart');
require('./components/new-purchase');
require('./components/pos');
require('./components/dashboard');
require('./components/account');
require('./components/image-remove');


// Vue Components ============
if($('#vue-app').length){

    Vue.component('v-select', require('vue-select').default);
    Vue.component('supplier-selector', require('./vue-components/SupplierSelector').default);
    Vue.component('customer-selector', require('./vue-components/CustomerSelector').default);

    const app = new Vue({
        el: '#vue-app'
    });

}
