var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "customer-selector",
      attrs: {
        "data-customer-name": _vm.customer.name,
        "data-customer-phone": _vm.customer.phone
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-6 pr-1" },
          [
            _c("v-select", {
              attrs: {
                label: "phone",
                placeholder: "Search Customers...",
                inputId: "customer",
                options: _vm.customers,
                taggable: ""
              },
              on: { search: _vm.searchCustomers, input: _vm.selectCustomer },
              scopedSlots: _vm._u([
                {
                  key: "option",
                  fn: function(customer) {
                    return [
                      _c("strong", [_vm._v(_vm._s(customer.name))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "small" }, [
                        _vm._v(_vm._s(customer.phone))
                      ])
                    ]
                  }
                },
                {
                  key: "selected-option",
                  fn: function(customer) {
                    return [
                      _c("div", { staticClass: "selected d-center" }, [
                        _c("strong", [_vm._v(_vm._s(customer.phone))])
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6 pl-1" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.customer.name,
                expression: "customer.name"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              placeholder: "Customer Name",
              name: "phone"
            },
            domProps: { value: _vm.customer.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.customer, "name", $event.target.value)
              }
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }