<template>
    <div class="customer-selector"
         :data-customer-name="customer.name"
         :data-customer-phone="customer.phone">

        <div class="row">
            <div class="col-sm-6 pr-1">
                <v-select label="phone"
                          placeholder="Search Customers..."
                          inputId="customer"
                          :options="customers"
                          taggable
                          @search="searchCustomers"
                          @input="selectCustomer">
                    <template slot="option" slot-scope="customer">
                        <strong>{{ customer.name }}</strong>
                        <div class="small">{{ customer.phone }}</div>
                    </template>
                    <template slot="selected-option" slot-scope="customer">
                        <div class="selected d-center">
                            <strong>{{ customer.phone }}</strong>
                        </div>
                    </template>
                </v-select>
            </div>

            <div class="col-sm-6 pl-1">
                <input type="text"
                       placeholder="Customer Name"
                       class="form-control"
                       name="phone"
                       v-model="customer.name">
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "CustomerSelector",

        data: () => {
            return {
                timer: null,
                customers: [],
                customer: {
                    name: '',
                    phone: ''
                },
                axiosSource: null,
            };
        },

        methods: {

            selectCustomer(customer){
                this.customer = $.extend({name: '', phone: ''}, customer);
            },

            searchCustomers(search, loading){

                if(this.timer){
                    clearTimeout(this.timer);
                    this.timer = null;
                }

                this.timer = setTimeout(() => {

                    if(this.axiosSource){
                        this.axiosSource.cancel();
                    }

                    // Get new Token.
                    this.axiosSource = axios.CancelToken.source();

                    loading(true);

                    axios.get('/api/customer', {
                        params: { q: search },
                        cancelToken: this.axiosSource.token,

                    }).then((res) => {
                        this.customers = res.data;

                    }).catch((error) => {
                        if(axios.isCancel(error)) return;
                        // Handle Other Exceptions

                    }).then(() => { // Always Executed
                        loading(false);
                        this.cancelAjax = null;
                    });


                }, 300);

            }

        },
    }
</script>

<style scoped>

</style>
