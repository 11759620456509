var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "supplier-selector",
      attrs: { "data-supplier-id": _vm.supplier ? _vm.supplier.id : null }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-center align-items-center" },
        [
          _c(
            "div",
            { staticClass: "form-group flex-grow-1 mr-2" },
            [
              _c("v-select", {
                attrs: {
                  label: "name",
                  placeholder: "Start typing to search for suppliers...",
                  inputId: "supplier",
                  options: _vm.suppliers
                },
                on: { search: _vm.searchSuppliers, input: _vm.selectSupplier },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function(supplier) {
                      return [
                        _c("strong", [_vm._v(_vm._s(supplier.name))]),
                        _vm._v(" "),
                        supplier.code.length
                          ? _c("span", { staticClass: "text-muted" }, [
                              _vm._v("#" + _vm._s(supplier.code))
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "selected-option",
                    fn: function(supplier) {
                      return [
                        _c("div", { staticClass: "selected d-center" }, [
                          _c("strong", [_vm._v(_vm._s(supplier.name))]),
                          _vm._v(" "),
                          supplier.code.length
                            ? _c("span", { staticClass: "text-muted" }, [
                                _vm._v("#" + _vm._s(supplier.code))
                              ])
                            : _vm._e()
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.supplier
        ? _c("div", { staticClass: "supplier" }, [
            _c("div", { staticClass: "border-left pl-3" }, [
              _c("div", { staticClass: "supplisupplierer-name h3 mb-0" }, [
                _vm._v(_vm._s(_vm.supplier.name))
              ]),
              _vm._v(" "),
              _vm.supplier.code.length
                ? _c(
                    "div",
                    {
                      staticClass: "supplier-code small font-weight-bold mb-1"
                    },
                    [_vm._v("#" + _vm._s(_vm.supplier.code))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "contact-details" }, [
                _vm.supplier.contact_name.length
                  ? _c(
                      "div",
                      { staticClass: "supplier-name font-weight-bold" },
                      [_vm._v(_vm._s(_vm.supplier.contact_name))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.supplier.contact_phone.length
                  ? _c("div", { staticClass: "supplier-phone" }, [
                      _vm._v(_vm._s(_vm.supplier.contact_phone))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.supplier.contact_email.length
                  ? _c("div", { staticClass: "supplier-email" }, [
                      _vm._v(_vm._s(_vm.supplier.contact_email))
                    ])
                  : _vm._e()
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "supplier" } }, [
      _c("strong", [_vm._v("Supplier (Optional)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }