$(document).ready(() => {

    $('body').on('click', '.remove-image', function( e ) {
        e.preventDefault();

        const $input = $(this)
            .parent().parent()
            .find('.input-remove-image');

        $input.prop('checked', !$input.prop('checked'));
    })

});