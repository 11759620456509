(($) => {

    $.urlParams = ( object ) => {
        let str = '';
        for (const key in object) {
            if( !object.hasOwnProperty( key ) ) continue;
            if (str !== '') { str += '&'; }
            str += key + '=' + encodeURIComponent( object[ key ] );
        }
        return str;
    };

})(jQuery);