$(document).ready(function(){


    const $modal = $('#modal-deposit');
    if( $modal.length === 0 ) return;

    const $input_amount = $('#action-amount');
    const $input_reason = $('#action-reason');
    const $input_balance = $('#action-balance');
    const $input_mode = $('#action-mode');
    const $input_original_balance = $('#original-balance');

    $modal.on('show.bs.modal', e => {
        const $btn = $(e.relatedTarget);
        const mode = $btn.data('mode');

        $modal.data('mode', mode);
        $input_mode.val(mode);
        $modal.find('.action-name').html(mode.ucFirst());

        $input_amount.val(0);
        $input_reason.val('');

        calculate_effective_balance();
    });

    $input_amount.on('keyup change', calculate_effective_balance);

    function calculate_effective_balance(){
        const original_balance = parseFloat( $input_original_balance.val() );
        let amount = parseFloat( $input_amount.val() );
        if( isNaN( amount ) ) amount = 0;
        if( $modal.data('mode') === 'deposit' ){
            amount = original_balance + amount;
        }else{
            amount = original_balance - amount;
        }
        $input_balance.val( amount );
    }

});