function dotted_key_to_object(key, val, accessor){

    accessor = ( typeof accessor === "undefined" ) ? {} : accessor;
    let accessor_sub = accessor;

    key = key.split('.');
    for( let i = 0; i < key.length; i++ ){
        if( !key.hasOwnProperty( i ) ) continue;
        const index = key[i];
        if( typeof accessor_sub[ index ] === "undefined" ){
            if( i === key.length - 1 ){
                accessor_sub[ index ] = val;
            }else{
                accessor_sub[ index ] = {};
            }
        }
        accessor_sub = accessor_sub[ index ];
    }
    return accessor;
}


const flatObjectToHierarchical = function(object){
    object = $.extend({}, object);
    for( const key in object ){
        if( !object.hasOwnProperty( key ) ) continue;
        dotted_key_to_object( key, object[key], object );
        delete object[key];
    }
    return object;
};


window.flatObjectToHierarchical = flatObjectToHierarchical;
